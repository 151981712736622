import { useState } from 'react';
import PropTypes from 'prop-types';
import ModalVideo from 'react-modal-video';
import { Icon } from '@iconify-icon/react';

const propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  category: PropTypes.string.valueOf(['article', 'video']),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

const defaultProps = {
  category: 'article',
  className: '',
};

function HelpLink({ url, className, category, children }) {
  const [isOpen, setOpen] = useState(false);

  if (category === 'video') {
    return (
      <>
        {category === 'video' && (
          <ModalVideo
            channel="youtube"
            autoplay
            isOpen={isOpen}
            videoId={url}
            onClose={() => setOpen(false)}
          />
        )}
        <button
          type="button"
          onClick={() => setOpen(true)}
          className={className || 'd-inline btn btn-link btn-chromeless initial-line-height'}
        >
          <Icon icon="custom:video-project" inline className="mr-1" />
          {children}
        </button>
      </>
    );
  }
  return (
    <a href={url} rel="noreferrer noopener" target="_blank" className={className}>
      <Icon icon="custom:e-book" inline className="mr-1" />
      {children}
    </a>
  );
}

HelpLink.propTypes = propTypes;
HelpLink.defaultProps = defaultProps;

export default HelpLink;
